import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  MiniMap,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  getOutgoers,
  useStoreApi
} from "reactflow";

import "./flow.css";
import "../../../assets/css/custom.css";
import cloneDeep from "lodash/cloneDeep";

import ButtonEdge from "./EdgeTypes/ButtonEdge";
import SubscriptionAction from "redux/subscription/action";
import {
  saveFlow,
  checkToken,
  getEditData,
  getDomainAndCompanyDetails
} from "services/flowServices";

import LoaderComponent from "components/common/LoaderComponent";
import TestingNode from "./Components/TestingNode";
import TestingListNode from "./Components/TestingListNode";
import TestingButtonUrlNode from "./Components/TestingButtonUrlNode";
import TestingTestNode from "./Components/TestingTestNode";
import TestingSingleProductNode from "./Components/TestingSingleProductNode";
import TestingMediaButtonNode from "./Components/TestingMediaButtonNode";
import TestingTemplateNode from "./Components/TestingTemplateNode";
import TestingMultiProductNode from "./Components/TestingMultiProductNode";
import CustomSideBarPreview from "../Trying/Components/CustomSideBarPreview";
import TestingFormBuilder from "./Components/TestingFormBuilder";
import InterventionNode from "./Components/InterventionNode";
import AttributeNode from "./Components/AttributeNode";
import TagsNode from "./Components/TagsNode";
import QuestionNode from "./Components/QuestionNode";
import AskLocationNode from "./Components/AskLocationNode";
import AskMediaNode from "./Components/AskMediaNode";
import NewListNode from "./Components/NewListNode";
import AskAddressNode from "./Components/AskAddressNde";
import FormNode from "./Components/FormNode";
import NewCommonNode from "./Components/NewCommonNode";
import RestartNode from "./Components/RestartNode";
import AINode from "./Components/AINode";
import { Box, Modal, Typography } from "@mui/material";
import NewCommonNodeMedia from "./Components/NewCommonNodeMedia";
import NewCommonNodeList from "./Components/NewCommonNodeList";
import NewCommonNodeTextOnly from "./Components/NewCommonNodeTextOnly";
import PaymentNode from "./Components/PaymentNode";
import AppointmentNode from "./Components/AppointmentNode";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4
};

const initalEdges = [];

const BACKEND_URI =
  window.location.origin.replace(
    process.env.REACT_APP_FLOW_SUBDOMAIN
      ? process.env.REACT_APP_FLOW_SUBDOMAIN
      : "",
    ""
  ) + "/";

function MainFile() {
  const reactFlowWrapper = useRef(null);
  const store = useStoreApi();

  const [isLoading, setIsLoading] = useState(false);

  const [sidebarState, setSidebarState] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState(initalEdges);
  const [selectedNode, setSelectedNode] = useState(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  // const [finalObjectOfNode, setFinalObjectOfNode] = useState({});
  const [jsonForAllParents, setJsonForAllParents] = useState({});
  const [previewForWhatsapp, setPreviewForWhatsapp] = useState(false);
  const [isChildLoading, setIsChildLoading] = useState(false);
  const [submitCounter, setSubmitCounter] = useState(0);
  const [errorsArray, setErrorsArray] = useState([]);
  const [initialNode, setInitialNode] = useState({});
  const [isValid, setIsValid] = useState(false);

  const [token, setToken] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [templateReply, setTemplateReply] = useState("");
  const [templates, setTemplates] = useState("");
  const [trigger, setTrigger] = useState("");
  const [verifyToken, setVerifyToken] = useState(false);
  const [isEdit, setIsEdit] = useState("");

  const [goBackModalOpen, setGoBackModalOpen] = useState(false);

  const toggleGoBackModal = () => {
    setGoBackModalOpen(!goBackModalOpen);
  };

  useEffect(() => {
    // Function to extract query parameters from the URL
    const extractQueryParams = () => {
      const searchParams = new URLSearchParams(window.location.search);

      // Extract and set values for each query parameter
      setToken(searchParams.get("_token") || "");
      setCompanyId(searchParams.get("company_id") || "");
      setTitle(searchParams.get("title") || "");
      setType(searchParams.get("type") || "");
      setTemplateReply(searchParams.get("template_reply") || "");
      setTemplates(searchParams.get("templates") || "");
      setTrigger(searchParams.get("trigger") || "");
      setIsEdit(searchParams.get("edit_id") || "");
    };

    extractQueryParams();
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.get("_token") !== undefined) {
      const token = searchParams.get("_token");
      const dataTosend = {
        token
      };
      handleVerifyToken(dataTosend);
    }

    if (
      searchParams.get("edit_id") !== undefined &&
      searchParams.get("edit_id") !== null
    ) {
      const data = {
        company_id: searchParams.get("company_id"),
        id: searchParams.get("edit_id")
      };
      handleSetEditData(data);
    }
  }, []);

  useEffect(() => {
    if (companyId) {
      fetchDomainAndCompanyInfo();
    }
  }, [companyId]);

  const handleVerifyToken = async tokenToSend => {
    const res = await checkToken(tokenToSend);
    if (res) {
      if (res.status_code === 200) {
        setVerifyToken(true);
      } else {
        window.location.href = `${BACKEND_URI}flows`;
        console.log("something went wrong");
      }
    }
    // }  else {
    // setVerifyToken(true);
    // }
  };

  const fetchDomainAndCompanyInfo = async () => {
    const res = await getDomainAndCompanyDetails(companyId);

    if (res.data) {
      // Set the page title
      document.title = res.data.white_label.name;

      // Set the favicon
      const link1 =
        document.querySelector("link[rel='icon']") ||
        document.createElement("link");
      link1.rel = "icon";
      if (res.data.company.favicon_ac_192) {
        link1.href = res.data.company.favicon_ac_192;
      } else if (res.data.white_label.favicon_ac_192) {
        link1.href = res.data.white_label.favicon_ac_192;
      }
      document.head.appendChild(link1);

      const link2 =
        document.querySelector("link[rel='shortcut icon']") ||
        document.createElement("link");
      link2.rel = "shortcut icon";
      if (res.data.company.favicon_32) {
        link2.href = res.data.company.favicon_32;
      } else if (res.data.white_label.favicon_32) {
        link2.href = res.data.white_label.favicon_32;
      }
      document.head.appendChild(link2);

      const link3 =
        document.querySelector("link[rel='apple-touch-icon']") ||
        document.createElement("link");
      link3.rel = "apple-touch-icon";
      if (res.data.company.favicon_apple_touch) {
        link3.href = res.data.company.favicon_apple_touch;
      } else if (res.data.white_label.favicon_apple_touch) {
        link3.href = res.data.white_label.favicon_apple_touch;
      }
      document.head.appendChild(link3);

      const metaDescription = document.querySelector(
        "meta[name='description']"
      );
      if (metaDescription) {
        metaDescription.setAttribute(
          "content",
          res.data.white_label.meta.description
        );
      } else {
        const newMeta = document.createElement("meta");
        newMeta.name = "description";
        newMeta.content = res.data.white_label.meta.description;
        document.head.appendChild(newMeta);
      }

      // Open Graph Title update
      const ogTitle = document.querySelector("meta[property='og:title']");
      if (ogTitle) {
        ogTitle.setAttribute("content", res.data.white_label.meta.og_title);
      } else {
        const newOgTitle = document.createElement("meta");
        newOgTitle.setAttribute("property", "og:title");
        newOgTitle.setAttribute("content", res.data.white_label.meta.og_title);
        document.head.appendChild(newOgTitle);
      }

      // Open Graph Description update
      const ogDescription = document.querySelector(
        "meta[property='og:description']"
      );
      if (ogDescription) {
        ogDescription.setAttribute(
          "content",
          res.data.white_label.meta.og_description
        );
      } else {
        const newOgDesc = document.createElement("meta");
        newOgDesc.setAttribute("property", "og:description");
        newOgDesc.setAttribute(
          "content",
          res.data.white_label.meta.og_description
        );
        document.head.appendChild(newOgDesc);
      }
    }
  };

  const { success, error, fetching } = SubscriptionAction;

  const handleSetEditData = async data => {
    try {
      setIsLoading(true);
      const res = await getEditData(data);
      if (res) {
        // console.log(res, "edit data res here");
        if (res?.data?.edges) {
          setEdges(res?.data?.edges);
        }
        if (res?.data?.nodes) {
          setNodes(res?.data?.nodes);
        }
        // setToken(res?.data?._token);
        // setCompanyId(res?.data?.company_id);
        // setTitle(res?.data?.title);
        // setType(res?.data?.type);
        // setTemplateReply(res?.data?.template_reply);
        // setTemplates(res?.data?.templates);
        // setTrigger(res?.data?.trigger);
      }
    } catch (error) {
      console.log(error, "Error while trying to set edit data");
    } finally {
      setIsLoading(false);
    }
  };

  const onNodesChange = useCallback(
    changes => setNodes(nds => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    changes => setEdges(eds => applyEdgeChanges(changes, eds)),
    []
  );

  const nodeTypes = useMemo(
    () => ({
      textButtons: props => (
        <TestingNode
          props={props}
          deleteParentNode={deleteParentNode}
          onTextChange={handleTextChange}
          setSelectedNode={setSelectedNode}
          setPreviewState={setPreviewForWhatsapp}
          duplicateNode={handleDuplicateNode}
          submitCounter={submitCounter}
          setIsChildLoading={setIsChildLoading}
          setErrorsArray={setErrorsArray}
        />
      ),
      textList: props => (
        <TestingListNode
          props={props}
          deleteParentNode={deleteParentNode}
          onTextChange={handleTextChange}
          setPreviewState={setPreviewForWhatsapp}
          setSelectedNode={setSelectedNode}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
        />
      ),
      textLink: props => (
        <TestingButtonUrlNode
          props={props}
          onTextChange={handleTextChange}
          deleteParentNode={deleteParentNode}
          setPreviewState={setPreviewForWhatsapp}
          setSelectedNode={setSelectedNode}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
        />
      ),
      Text: props => (
        <TestingTestNode
          props={props}
          onTextChange={handleTextChange}
          deleteParentNode={deleteParentNode}
          setSelectedNode={setSelectedNode}
          setPreviewState={setPreviewForWhatsapp}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
        />
      ),
      singleProduct: props => (
        <TestingSingleProductNode
          props={props}
          onTextChange={handleTextChange}
          deleteParentNode={deleteParentNode}
          setPreviewState={setPreviewForWhatsapp}
          setSelectedNode={setSelectedNode}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
          companyId={companyId}
        />
      ),

      mediaButtons: props => (
        <TestingMediaButtonNode
          props={props}
          key={props?.id}
          deleteParentNode={deleteParentNode}
          setIsChildLoading={setIsChildLoading}
          setPreviewState={setPreviewForWhatsapp}
          companyId={companyId}
          setSelectedNode={setSelectedNode}
          onTextChange={handleTextChange}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
          onImageChange={handleImageChange}
        />
      ),
      Template: props => (
        <TestingTemplateNode
          props={props}
          onTextChange={handleTextChange}
          setSelectedNode={setSelectedNode}
          setSidebarState={setSidebarState}
          //   onImageUpload={handleImageUpload}
          deleteParentNode={deleteParentNode}
          setIdForTemplate={setIdForTemplateNode}
          setIsChildLoading={setIsChildLoading}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
          editId={isEdit}
        />
      ),
      multiProduct: props => (
        <TestingMultiProductNode
          props={props}
          deleteParentNode={deleteParentNode}
          onTextChange={handleTextChange}
          setPreviewState={setPreviewForWhatsapp}
          setSelectedNode={setSelectedNode}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
          companyId={companyId}
        />
      ),
      FormBuilder: props => (
        <TestingFormBuilder
          props={props}
          onTextChange={handleTextChange}
          deleteParentNode={deleteParentNode}
          setSelectedNode={setSelectedNode}
          setPreviewState={setPreviewForWhatsapp}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
        />
      ),
      NewCommonNode: props => (
        <NewCommonNode
          props={props}
          companyId={companyId}
          setIsChildLoading={setIsChildLoading}
          onTextChange={handleTextChange}
          deleteParentNode={deleteParentNode}
          setSelectedNode={setSelectedNode}
          setPreviewState={setPreviewForWhatsapp}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
        />
      ),
      NewCommonNodeMedia: props => (
        <NewCommonNodeMedia
          props={props}
          companyId={companyId}
          setIsChildLoading={setIsChildLoading}
          onTextChange={handleTextChange}
          deleteParentNode={deleteParentNode}
          setSelectedNode={setSelectedNode}
          setPreviewState={setPreviewForWhatsapp}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
        />
      ),
      NewCommonNodeList: props => (
        <NewCommonNodeList
          props={props}
          companyId={companyId}
          setIsChildLoading={setIsChildLoading}
          onTextChange={handleTextChange}
          deleteParentNode={deleteParentNode}
          setSelectedNode={setSelectedNode}
          setPreviewState={setPreviewForWhatsapp}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
        />
      ),
      NewCommonNodeText: props => (
        <NewCommonNodeTextOnly
          props={props}
          companyId={companyId}
          setIsChildLoading={setIsChildLoading}
          onTextChange={handleTextChange}
          deleteParentNode={deleteParentNode}
          setSelectedNode={setSelectedNode}
          setPreviewState={setPreviewForWhatsapp}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
        />
      ),
      InterventionNode: props => (
        <InterventionNode
          props={props}
          onTextChange={handleTextChange}
          deleteParentNode={deleteParentNode}
          setSelectedNode={setSelectedNode}
          setPreviewState={setPreviewForWhatsapp}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
        />
      ),
      RestartNode: props => (
        <RestartNode
          props={props}
          onTextChange={handleTextChange}
          deleteParentNode={deleteParentNode}
          setSelectedNode={setSelectedNode}
          setPreviewState={setPreviewForWhatsapp}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
        />
      ),
      AINode: props => (
        <AINode
          props={props}
          onTextChange={handleTextChange}
          deleteParentNode={deleteParentNode}
          setSelectedNode={setSelectedNode}
          setPreviewState={setPreviewForWhatsapp}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
        />
      ),

      AttributeNode: props => (
        <AttributeNode
          props={props}
          onTextChange={handleTextChange}
          setSelectedNode={setSelectedNode}
          setSidebarState={setSidebarState}
          deleteParentNode={deleteParentNode}
          setIsChildLoading={setIsChildLoading}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
          companyId={companyId}
        />
      ),
      TagsNode: props => (
        <TagsNode
          props={props}
          onTextChange={handleTextChange}
          setSelectedNode={setSelectedNode}
          setSidebarState={setSidebarState}
          deleteParentNode={deleteParentNode}
          setIsChildLoading={setIsChildLoading}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
          companyId={companyId}
        />
      ),
      QuestionNode: props => (
        <QuestionNode
          onTextChange={handleTextChange}
          setSelectedNode={setSelectedNode}
          setSidebarState={setSidebarState}
          deleteParentNode={deleteParentNode}
          setIsChildLoading={setIsChildLoading}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
          companyId={companyId}
          props={props}
        />
      ),
      LocationNode: props => (
        <AskLocationNode
          onTextChange={handleTextChange}
          setSelectedNode={setSelectedNode}
          setSidebarState={setSidebarState}
          deleteParentNode={deleteParentNode}
          setIsChildLoading={setIsChildLoading}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
          companyId={companyId}
          props={props}
        />
      ),
      AddressNode: props => (
        <AskAddressNode
          onTextChange={handleTextChange}
          setSelectedNode={setSelectedNode}
          setSidebarState={setSidebarState}
          deleteParentNode={deleteParentNode}
          setIsChildLoading={setIsChildLoading}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
          companyId={companyId}
          props={props}
        />
      ),
      AskMediaNode: props => (
        <AskMediaNode
          onTextChange={handleTextChange}
          setSelectedNode={setSelectedNode}
          setSidebarState={setSidebarState}
          deleteParentNode={deleteParentNode}
          setIsChildLoading={setIsChildLoading}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
          companyId={companyId}
          props={props}
        />
      ),
      NewListNode: props => (
        <NewListNode
          props={props}
          deleteParentNode={deleteParentNode}
          onTextChange={handleTextChange}
          setPreviewState={setPreviewForWhatsapp}
          setSelectedNode={setSelectedNode}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
        />
      ),
      FormNode: props => (
        <FormNode
          props={props}
          onTextChange={handleTextChange}
          deleteParentNode={deleteParentNode}
          setPreviewState={setPreviewForWhatsapp}
          setSelectedNode={setSelectedNode}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
          companyId={companyId}
        />
      ),
      PaymentNode: props => (
        <PaymentNode
          props={props}
          onTextChange={handleTextChange}
          deleteParentNode={deleteParentNode}
          setPreviewState={setPreviewForWhatsapp}
          setSelectedNode={setSelectedNode}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
          companyId={companyId}
        />
      ),
      AppointmentNode: props => (
        <AppointmentNode
          props={props}
          onTextChange={handleTextChange}
          deleteParentNode={deleteParentNode}
          setPreviewState={setPreviewForWhatsapp}
          setSelectedNode={setSelectedNode}
          duplicateNode={handleDuplicateNode}
          setErrorsArray={setErrorsArray}
          companyId={companyId}
        />
      )
    }),
    [companyId, isEdit]
  );

  const edgeTypes = useMemo(
    () => ({
      buttonEdge: props => (
        <ButtonEdge props={props} handleEdgeDelete={handleEdgeDelete} />
      )
    }),
    []
  );

  const setIdForTemplateNode = (nodeId, id) => {
    try {
      setNodes(prevNodes => {
        //   const parentId = extractParentId(nodeId);
        //   const nodeIndex = prevNodes.findIndex((x) => x.id == parentId);

        const childNodeIndex = prevNodes.findIndex(x => x.id == nodeId);

        prevNodes[childNodeIndex] = {
          ...prevNodes[childNodeIndex],
          data: {
            ...prevNodes[childNodeIndex]?.data,
            id: id
          }
        };
        return [...prevNodes];
      });
    } catch (error) {
      console.log(error, "Error while trying to set id in data");
    }
  };

  useEffect(() => {
    // console.log(errorsArray, "errors Array");
    handleChildError();
  }, [errorsArray]);

  const handleChildError = () => {
    try {
      if (errorsArray.length > 0) {
        setIsChildLoading(true);
      } else {
        setIsChildLoading(false);
      }
    } catch (error) {
      console.log(error, "error while to handle child error");
    }
  };
  const handleDuplicateNode = nodeId => {
    setNodes(prevNodes => {
      // console.log(nodeId, "Node id here in assasasasa", prevNodes?.length);
      // console.log("Previous nodes:", prevNodes);

      const nextNodeId = getNextNodeId(prevNodes);

      let tempInd;
      const nodeIndex = prevNodes.map((x, i) => {
        if (x.id == nodeId) {
          console.log(x?.id, "Asdasdas", nodeId);
          tempInd = i;
        }
      });
      console.log("Node index:", tempInd);
      const type = prevNodes[tempInd]?.type;
      console.log(type, "eueueueue");
      console.log("Next node ID:", nextNodeId);

      const position2 = {
        x: prevNodes[tempInd]?.position?.x + 400,
        y: prevNodes[tempInd]?.position?.y
      };
      console.log("New position:", position2);

      let buttonsArr = [];
      let valuesObj = {};
      if (type === "textButtons" || type === "mediaButtons") {
        if (prevNodes[tempInd]?.data?.value?.buttons?.length > 0) {
          prevNodes[tempInd]?.data?.value?.buttons.map((btns, index) => {
            let tempObj = {
              id: btns?.id,
              value: btns?.value,
              uniqueId: generateUniqueId()
            };
            buttonsArr.push(tempObj);
          });
        }

        valuesObj = {
          ...prevNodes[tempInd]?.data?.value,
          buttons: buttonsArr
        };
      } else if (type === "NewListNode") {
        if (prevNodes[tempInd]?.data?.value?.sections?.length > 0) {
          prevNodes[tempInd]?.data?.value?.sections.map((stns, index) => {
            let tempArr = [];
            if (stns?.item?.length > 0) {
              stns?.item?.map((itm, ind) => {
                let obj = {
                  index: itm?.index,
                  itemTitle: itm?.itemTitle,
                  itemDescription: itm?.itemDescription,
                  uniqueId: generateUniqueId()
                };
                tempArr.push(obj);
              });
            }
            let tempObj = {
              id: stns?.id,
              title: stns?.title,
              item: tempArr
            };
            buttonsArr.push(tempObj);
          });
        }
        valuesObj = {
          ...prevNodes[tempInd]?.data?.value,
          sections: buttonsArr
        };
        console.log(valuesObj, "valuesObj ueueueueu");
      } else if (
        type === "NewCommonNode" ||
        type === "NewCommonNodeMedia" ||
        type === "NewCommonNodeList" ||
        type === "NewCommonNodeText"
      ) {
        let data = prevNodes[tempInd]?.data?.value;
        //indexing will be same

        let indexing = data?.indexing;
        let textButtonsFinalArray = [];
        let mediaButtonsFinalArray = [];
        let textListsFinalArray = [];

        //handle textButtons
        if (data?.textButtons?.length > 0) {
          let textButonsArray = [];
          data?.textButtons?.map((textButton, ind) => {
            let buttonsArray = [];

            if (textButton?.buttons?.length > 0) {
              textButton?.buttons?.map((button, buttonIndex) => {
                let tempButtonObj = {
                  title: button?.title,
                  uniqueId: generateUniqueId()
                };

                buttonsArray.push(tempButtonObj);
              });
            }

            let tempObj = {
              ...data?.textButtons[ind],
              buttons: buttonsArray
            };

            textButonsArray.push([]);
          });
        }

        //handle mediaButtons

        //handle textLists
      } else {
        valuesObj = {
          ...prevNodes[tempInd]?.data?.value
        };
      }

      const nodeToadd = {
        id: nextNodeId,
        type,
        uniqueId:
          type === "AttributeNode" ||
          type === "TagsNode" ||
          type === "QuestionNode" ||
          type === "LocationNode" ||
          type === "AddressNode" ||
          type === "AskMediaNode" ||
          type === "FormNode" ||
          type === "AppointmentNode"
            ? generateUniqueId()
            : null,
        data: {
          label: `Parent Node ${nextNodeId}`,
          value: valuesObj,
          type,
          triggerFor: []
        },
        position: position2,
        style: {
          width: 270,
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0px 0px 25px #00000014"
        }
      };
      console.log("New node to add:", nodeToadd);

      const updatedNodes = [...prevNodes, nodeToadd];
      console.log("Updated nodes:", updatedNodes);

      return updatedNodes;
    });
  };

  ////

  // const handleDuplicateNode = (nodeId) => {
  //   setNodes((prevNodes) => {
  //     const tempInd = prevNodes.findIndex((x) => x.id === nodeId);

  //     if (tempInd === -1) {
  //       console.error(`Node with ID ${nodeId} not found.`);
  //       return prevNodes;
  //     }

  //     const originalNode = prevNodes[tempInd];
  //     const nextNodeId = getNextNodeId(prevNodes);

  //     // Create new node with updated position and cloned data
  //     const nodeToAdd = {
  //       id: nextNodeId,
  //       type: originalNode.type,
  //       uniqueId: generateUniqueId(),
  //       data: {
  //         ...originalNode.data,
  //         value: { ...originalNode.data.value }, // Ensure deep cloning if necessary
  //       },
  //       position: {
  //         x: originalNode.position.x + 400,
  //         y: originalNode.position.y,
  //       },
  //       style: {
  //         width: 270,
  //         backgroundColor: "white",
  //         borderRadius: "8px",
  //         boxShadow: "0px 0px 25px #00000014",
  //       },
  //     };

  //     // Update nodes array with the new node added
  //     const updatedNodes = [
  //       ...prevNodes.slice(0, tempInd + 1),
  //       nodeToAdd,
  //       ...prevNodes.slice(tempInd + 1),
  //     ];

  //     return updatedNodes;
  //   });
  // };

  const handleDuplicateNode2 = nodeId => {
    setNodes(prevNodes => {
      const tempInd = prevNodes.findIndex(x => x.id === nodeId);

      if (tempInd === -1) {
        console.error(`Node with ID ${nodeId} not found.`);
        return prevNodes;
      }

      const originalNode = prevNodes[tempInd];
      const nextNodeId = getNextNodeId(prevNodes);

      // Create new node with updated position and cloned data
      const nodeToAdd = {
        id: nextNodeId,
        type: originalNode.type,
        uniqueId: generateUniqueId(),
        data: cloneDeep(originalNode.data), // Use cloneDeep from lodash here
        position: {
          x: originalNode.position.x + 400,
          y: originalNode.position.y
        },
        style: {
          width: 270,
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0px 0px 25px #00000014"
        }
      };

      // Update nodes array with the new node added
      const updatedNodes = [
        ...prevNodes.slice(0, tempInd + 1),
        nodeToAdd,
        ...prevNodes.slice(tempInd + 1)
      ];

      return updatedNodes;
    });
  };

  const handleTextChange = (nodeId, newText) => {
    // Log information about the text change
    // console.log(
    //   `Text changed for node ${nodeId}:`,
    //   newText,
    //   "Aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
    // );

    // Handle the new text value here
    // You can update your state or perform any other action based on the text change

    setNodes(prevNodes => {
      // Create a copy of the nodes array
      let updatedNodes = [...prevNodes];
      // console.log(updatedNodes, "updatedNodes");

      // Find the index of the node with the given nodeId
      const nodeIndex = updatedNodes.findIndex(node => node.id == nodeId);

      if (nodeIndex !== -1) {
        // If the node is found, update its data property with the new text value
        updatedNodes[nodeIndex] = {
          ...updatedNodes[nodeIndex],
          data: {
            ...updatedNodes[nodeIndex].data,
            value: newText
            // uniqueId: generateUniqueId(),
          }
        };
      } else {
      }
      // console.log(updatedNodes, "updatedNodes");
      return updatedNodes;
    });
  };

  const handleImageChange = (nodeId, newText) => {
    try {
      setNodes(prevNodes => {
        const nodeIndex = prevNodes.findIndex(x => x.id == nodeId);

        if (nodeIndex !== -1) {
          prevNodes[nodeIndex] = {
            ...prevNodes[nodeIndex],
            data: {
              ...prevNodes[nodeIndex].data,
              value: newText
              // uniqueId: generateUniqueId(),
            }
          };
        } else {
        }

        return [...prevNodes];
      });
    } catch (error) {
      console.log(error, "Error while trying to handleImageChange");
    }
  };

  function sortNodesById(nodesArray) {
    return nodesArray.sort((a, b) => {
      const idA = a.id.toUpperCase();
      const idB = b.id.toUpperCase();

      if (idA < idB) {
        return -1;
      }
      if (idA > idB) {
        return 1;
      }

      return 0;
    });
  }

  function getNextNodeId(nodesArray) {
    if (nodesArray.length === 0) {
      // console.log("No nodes, returning 'A'");
      return "A";
    }

    // Custom sorting function to sort by ID length first, then alphabetically
    nodesArray.sort((a, b) => {
      if (a.id.length !== b.id.length) {
        return a.id.length - b.id.length; // Sort by length first
      } else {
        return a.id.localeCompare(b.id); // If lengths are the same, sort alphabetic`all`y
      }
    });

    const parentIds = [];

    nodesArray.forEach(node => {
      // Extract the parent ID from the node ID
      const parentId = node.id.split("-")[0];

      // Check if the parent ID is not already in the array before adding
      if (!parentIds.some(item => item.value === parentId)) {
        parentIds.push({ value: parentId, label: parentId });
      }
    });

    // console.log("Sorted Parent IDs:", parentIds);

    const lastNodeId = parentIds[parentIds.length - 1].value;

    // Function to increment a base-26 string
    const incrementBase26 = str => {
      let carry = true;
      let incremented = "";
      for (let i = str.length - 1; i >= 0; i--) {
        const charCode = str.charCodeAt(i);
        let newCharCode;
        if (carry) {
          newCharCode = charCode + 1;
          carry = false;
        } else {
          newCharCode = charCode;
        }
        if (newCharCode > 90) {
          // 'Z' in ASCII
          incremented = "A" + incremented;
          carry = true;
        } else {
          incremented = String.fromCharCode(newCharCode) + incremented;
        }
      }
      if (carry) {
        incremented = "A" + incremented;
      }
      return incremented;
    };

    // If the last node ID is 'Z', return 'AA'
    if (lastNodeId === "Z") {
      // console.log("Last Node ID is 'Z', returning 'AA'");
      return "AA";
    }

    // Otherwise, increment the last node ID
    let nextNodeId = incrementBase26(lastNodeId);

    // Check if the generated ID already exists, if so, increment again
    while (nodesArray.some(node => node.id === nextNodeId)) {
      nextNodeId = incrementBase26(nextNodeId);
    }

    // console.log("Next Node ID:", nextNodeId);
    return nextNodeId;
  }

  function generateUniqueId() {
    const alphanumericCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uniqueKey = "";

    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(
        Math.random() * alphanumericCharacters.length
      );
      uniqueKey += alphanumericCharacters.charAt(randomIndex);
    }

    return uniqueKey;
  }
  function extractParentIds(nodesArray) {
    const parentIdsArray = [];

    nodesArray.forEach(node => {
      // Extract the parent ID from the node ID
      const parentId = node.id.split("-")[0];

      // Check if the parent ID is not already in the array before adding
      if (!parentIdsArray.some(item => item.id === parentId)) {
        parentIdsArray.push(node);
      }
    });

    return parentIdsArray;
  }

  useEffect(() => {
    // console.log(edges, "edges inside useefect");
    removeExtraTriggerFor();
    handleTriggerForObjectFix();
  }, [edges]);

  useEffect(() => {
    console.log(nodes, "nodes inside useefect of nodes");
    handleSetInitialNode();
    checkValidityOfNodes();
  }, [nodes]);

  const handleTriggerForObjectFix = () => {
    try {
      setNodes(prevNodes => {
        return prevNodes.map(node => {
          // console.log("DebuggingLog: Inside map function"); // DebuggingLog

          if (node?.data?.triggerFor?.length > 0) {
            // console.log("DebuggingLog: triggerFor array exists"); // DebuggingLog

            if (node?.type === "textList") {
              // console.log("DebuggingLog: Node type is textList"); // DebuggingLog

              node?.data?.triggerFor?.forEach(tFor => {
                const tempId = tFor?.sourceHandle?.split("_")[1];
                // console.log("DebuggingLog: tempId for textList:", tempId); // DebuggingLog

                const matchingSection = node?.data?.value?.sections?.find(
                  section => section?.id === tempId
                );

                if (
                  matchingSection &&
                  tFor?.uniqueId !== matchingSection?.uniqueId
                ) {
                  tFor.uniqueId = matchingSection?.uniqueId;
                  // console.log(
                  //   "DebuggingLog: Updated uniqueId in triggerFor for textList node"
                  // ); // DebuggingLog
                }
              });
            } else if (
              node?.type === "textButtons" ||
              node?.type === "mediaButtons"
            ) {
              // console.log(
              //   "DebuggingLog: Node type is textButtons or mediaButtons"
              // ); // DebuggingLog

              node?.data?.triggerFor?.forEach(tFor => {
                const tempId = tFor?.sourceHandle?.split("_")[1];
                // console.log(
                //   "DebuggingLog: tempId for textButtons or mediaButtons:",
                //   tempId
                // ); // DebuggingLog

                const matchingSection = node?.data?.value?.buttons?.find(
                  section => section?.id === tempId
                );

                if (
                  matchingSection &&
                  tFor?.uniqueId !== matchingSection?.uniqueId
                ) {
                  tFor.uniqueId = matchingSection?.uniqueId;
                  // console.log(
                  //   "DebuggingLog: Updated uniqueId in triggerFor for textButtons or mediaButtons node"
                  // ); // DebuggingLog
                }
              });
            }
          }
          return node;
        });
      });
    } catch (error) {
      console.log(error, "Error while trying to fix the triggerFor Arrays");
    }
  };

  const handleSetInitialNode = () => {
    try {
      setNodes(prevNodes => {
        let sortedNodes = sortNodesById(prevNodes);

        sortedNodes.find(x => {
          let isSource = false;
          let isTarget = false;

          edges.forEach(y => {
            if (y.source == x.id) {
              isSource = true;
            } else if (y.target == x.id) {
              isTarget = true;
            }
          });

          if (isSource && !isTarget) {
            // console.log("Final node is", x);
            setInitialNode(x);
            // initialNode = x.id;
            return true; // Stops the loop
          }
        });
        return prevNodes;
      });
    } catch (error) {
      console.log(error, "error while trying to set initial node");
    }
  };

  const checkValidityOfNodes = () => {
    try {
      const sortedNodes = sortNodesById(nodes);
      let noNodeWithoutEdge = true; // Flag to track if any node is not a target for any edge

      sortedNodes.forEach(x => {
        if (x.id !== initialNode?.id) {
          let isTargetForAnyEdge = false;
          edges.forEach(edge => {
            if (edge.target === x.id) {
              isTargetForAnyEdge = true;
            }
          });
          if (!isTargetForAnyEdge) {
            setIsValid(false);
            noNodeWithoutEdge = false;
            return; // Exit loop if condition is met
          }
        }
      });

      if (noNodeWithoutEdge) {
        setIsValid(true);
      }
    } catch (error) {
      console.log(error, "Error while trying to get validity of nodes");
    }
  };

  // const removeExtraTriggerFor = () => {
  //   setNodes((prevNodes) => {
  //     const updatedNodes = prevNodes.map((node) => {
  //       const filteredTriggerFor = node?.data?.triggerFor?.reduce(
  //         (uniqueTriggers, currentTrigger) => {
  //           const isDuplicate = uniqueTriggers.some(
  //             (trigger) =>
  //               trigger.triggerForId === currentTrigger.triggerForId &&
  //               trigger.sourceHandle === currentTrigger.sourceHandle
  //           );

  //           if (!isDuplicate) {
  //             uniqueTriggers.push(currentTrigger);
  //           }

  //           return uniqueTriggers;
  //         },
  //         []
  //       );

  //       return {
  //         ...node,
  //         data: {
  //           ...node?.data,
  //           triggerFor: filteredTriggerFor,
  //         },
  //       };
  //     });

  //     return updatedNodes;
  //   });
  // };

  const removeExtraTriggerFor = () => {
    setNodes(prevNodes => {
      const updatedNodes = prevNodes.map(node => {
        if (node?.data?.triggerFor) {
          const filteredTriggerFor = node.data.triggerFor.filter(trigger => {
            // Check if the target ID exists in nodes
            const targetExists = prevNodes.some(
              n => n.id === trigger.triggerForId
            );

            // Check for duplicates
            const isDuplicate = node.data.triggerFor.some(
              t =>
                t.triggerForId === trigger.triggerForId &&
                t.sourceHandle === trigger.sourceHandle &&
                t.uniqueKey !== trigger.uniqueKey // Ensure different unique keys
            );

            // Include the triggerFor object if the target exists and it's not a duplicate
            return targetExists && !isDuplicate;
          });

          return {
            ...node,
            data: {
              ...node.data,
              triggerFor: filteredTriggerFor
            }
          };
        }
        return node;
      });

      return updatedNodes;
    });
  };

  const deleteParentNode = parentId => {
    try {
      // console.log(parentId, "Parent id inside delete node function");
      // setNodes(sortNodesById(nodes.filter((node) => node.id !== parentId)));
      setNodes(prevNodes => {
        // debugger;
        // console.log(prevNodes, "prevNOdes inside de;ete node");
        // Filter out the parent node from the nodes array
        const updatedNodes = prevNodes.filter(node => node.id !== parentId);
        // console.log({ updatedNodes });
        const sortedNodes = sortNodesById(updatedNodes);
        return sortedNodes;
      });

      setEdges(prevEdges => {
        // debugger;
        // Filter out edges connected to the parent node
        const updatedEdges = prevEdges.filter(
          edge => edge.source !== parentId && edge.target !== parentId
        );
        return updatedEdges;
      });

      setErrorsArray(errArr => {
        let temp = errArr.filter(x => x?.id !== parentId);

        return [...temp];
      });
    } catch (error) {
      console.log(error, "error while trying to delete a parent node");
    }
  };

  const onConnect = useCallback(
    params => {
      // console.log(params, "params");
      // console.log(nodes, "nodes inside on connect");

      const outgoers = getOutgoers(params, nodes, edges);
      // console.log(outgoers, "bahar jane vale");

      //   const parent = extractParentId(params?.source);
      //   console.log({ parent });

      // Check if an edge with the same source already exists
      const isEdgeExists = edges.some(
        edge => params?.sourceHandle === edge.sourceHandle
      );

      // Check if the target is the same as its own parent
      const isSameParent = params?.target === params?.source;

      if (!isEdgeExists && !isSameParent) {
        setEdges(eds =>
          addEdge(
            {
              ...params,
              // markerStart: {
              //   type: MarkerType.ArrowClosed,
              //   width: 10,
              //   height: 100,
              //   color: "#FF0072",
              // },
              markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
                color: "#FF0072"
              },
              type: "buttonEdge",
              animated: true,
              style: { stroke: "var(--purple-color)" }
            },
            eds
          )
        );

        let uniqueId;
        const sourceId = nodes.findIndex(x => x.id == params.source);
        if (nodes[sourceId]?.type === "NewListNode") {
          const [prefix, id, tempId] = params?.sourceHandle?.split("_");
          // console.log(prefix, id, tempId, "common ids");

          const matchingSection = nodes[sourceId]?.data?.value?.sections?.find(
            section => section?.id == id
          );

          // console.log(matchingSection, "common matching section");
          if (
            matchingSection &&
            matchingSection?.item &&
            matchingSection?.item?.length > 0
          ) {
            const itemMatchingSection = matchingSection?.item?.find(
              itm => itm?.index == parseInt(tempId) + 1
            );
            // console.log(itemMatchingSection, "common matching item");

            uniqueId = itemMatchingSection?.uniqueId;
            // console.log(uniqueId, "common matching uniqueid");
          }
        } else if (
          nodes[sourceId]?.type === "textButtons" ||
          nodes[sourceId]?.type === "mediaButtons"
        ) {
          const tempId = params?.sourceHandle?.split("_")[1];

          const matchingSection = nodes[sourceId]?.data?.value?.buttons?.find(
            section => section?.id == tempId
          );
          if (matchingSection && matchingSection?.uniqueId !== undefined) {
            uniqueId = matchingSection?.uniqueId;
          }
        } else if (
          nodes[sourceId]?.type === "NewCommonNode" ||
          nodes[sourceId]?.type === "NewCommonNodeMedia" ||
          nodes[sourceId]?.type === "NewCommonNodeList" ||
          nodes[sourceId]?.type === "NewCommonNodeText"
        ) {
          const [
            id,
            type,
            localIndex,
            anotherIndex,
            oneMoreIndex
          ] = params?.sourceHandle?.split("_");
          console.log("inside new common node", type);
          if (type === "textLists") {
            console.log(params?.sourceHandle);
            const matchingSection =
              nodes[sourceId]["data"]["value"][type][localIndex]["sections"][
                anotherIndex
              ]["items"][oneMoreIndex];

            console.log(
              matchingSection,
              "matching section dhundhne ki koshish"
            );
            uniqueId = matchingSection.uniqueId;
          } else if (type === "textButtons" || type === "mediaButtons") {
            const matchingButton =
              nodes[sourceId]["data"]["value"][type][localIndex]["buttons"][
                anotherIndex
              ];
            console.log(matchingButton, "matching button dhundhne ki koshish");
            uniqueId = matchingButton.uniqueId;
          }
        }
        const trigggerForObj = {
          sourceHandle: params?.sourceHandle,
          triggerForId: params?.target,
          uniqueId: uniqueId
        };
        // console.log({ trigggerForObj });
        // debugger
        if (!nodes[sourceId]?.data?.triggerFor) {
          nodes[sourceId].data.triggerFor = [];
        }

        const tempArray = nodes[sourceId]?.data?.triggerFor;
        tempArray.push(trigggerForObj);
        // nodes[sourceId] = {
        //   ...nodes[sourceId],
        //   data: {
        //     ...nodes[sourceId].data,
        //     // triggerFor:[{souceHandle : params.sourceHandle, triggerForId : params.target}],
        //     triggerFor: tempArray,
        //     // uniqueId: generateUniqueId(),
        //   },
        // };

        nodes[sourceId].data.triggerFor = sortTriggerForArray(tempArray);

        // console.log(nodes[sourceId], "updated node");

        // console.log(edges, "Edges");
      } else if (isEdgeExists) {
        error("You can only have one response for an element");
        console.log("Edge with the same source already exists");
      } else {
        error("You cannot add an edge to its own parent");
        console.log("Edge target is the same as its own parent");
      }
    },
    [edges, nodes]
  );

  const onDragOver = useCallback(event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    event => {
      event.preventDefault();

      const type = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY
      });

      //   addCustomNode(type, position);
      // const newId = getNextNodeId(prevNodes);
      // const newNode = {
      //   id: newId,
      //   type,
      //   position,
      //   data: { label: `${type} node` },
      // };

      // setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );

  const handleEdgeDelete = edgeParamas => {
    // console.log(edgeParamas, "edgeparams");

    //update triggerFor value or remove key if possible

    // const parentIndex = nodes.findIndex((x) => {
    //   return x.id == edgeParamas?.source;
    // });
    // console.log({ parentIndex });
    // console.log(nodes[parentIndex].data.triggerFor,"nodes parent index")

    setNodes(prevNodes => {
      return prevNodes.map(x => {
        if (x?.data?.triggerFor?.length > 0) {
          const filteredTriggerFor = x.data.triggerFor.filter(y => {
            return (
              y?.sourceHandle !== edgeParamas.sourceHandleId ||
              y?.triggerForId !== edgeParamas?.target
            );
          });
          return {
            ...x,
            data: {
              ...x.data,
              triggerFor: filteredTriggerFor
            }
          };
        }
        return x; // Return the original node if triggerFor is empty or not present
      });
    });
    // setNodes(temp1);

    // delete nodes[parentIndex]?.data?.triggerFor;
    // delete nodes[parentIndex]?.data?.uniqueId;
    setEdges(edges => edges.filter(edge => edge.id !== edgeParamas.id));

    // nodes[parentIndex] = {
    //   ...nodes[parentIndex],
    //   data:{
    //     ...nodes[parentIndex].data,

    //   }
  };
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  const handleSettingSelectedNode = selectedNodeSent => {
    const nodeIndex = nodes.findIndex(x => x.id == selectedNode);

    return nodes[nodeIndex];
  };

  function sortTriggerForArray(arr) {
    // Creating a copy of the array to avoid modifying the original array
    const sortedArray = [...arr];

    // Sorting the array based on the sourceHandle key
    sortedArray.sort((a, b) => {
      // Extracting the sourceHandle values from the objects
      let sourceHandleA = a.sourceHandle;
      let sourceHandleB = b.sourceHandle;

      // Comparing the sourceHandle values
      if (sourceHandleA < sourceHandleB) {
        return -1; // A should come before B
      }
      if (sourceHandleA > sourceHandleB) {
        return 1; // B should come before A
      }
      return 0; // Values are equal
    });

    // Returning the sorted array
    return sortedArray;
  }

  const handleSetJsonForParent = () => {
    // console.log(nodes, "nodes");

    const sortedNodes1 = sortNodesById(nodes);
    // const allParentNodes = extractParentIds(sortedNodes1);

    let tempObj = {};
    let testObj = {};
    // let jsonForParent;
    sortedNodes1.map((node, index) => {
      //   const childOfThisParent = getChildNodes(node.id, nodes).childList;

      const childOfThisParent = [];
      if (node?.type === "textButtons") {
        // console.log("inside textButtons");
        let buttonObj = [];
        if (node?.data?.value?.buttons?.length > 0) {
          node?.data?.value?.buttons?.map((x, i) => {
            let obj = {
              [i]: {
                type: "reply",
                reply: {
                  id: x?.uniqueId,
                  title: x?.value
                }
              }
            };
            buttonObj.push(obj);
            obj = {};
          });
        }

        let jsonForParent = {
          interactive: {
            header: {
              type: "text",
              text: node?.data?.value?.header
            },
            type: "button",
            body: {
              text: node?.data?.value?.body
            },
            footer: {
              text: node?.data?.value?.footer
            },
            action: {
              buttons: buttonObj
            }
          }
        };

        // console.log({ jsonForParent });

        tempObj = {
          ...tempObj,
          [node?.id]: jsonForParent
        };

        return true;
      } else if (node?.type === "mediaButtons") {
        // console.log("inside mediaButtons");
        let buttonObj = [];

        if (node?.data?.value?.buttons.length > 0) {
          node?.data?.value?.buttons.map((x, i) => {
            let obj = {
              [i]: {
                type: "reply",
                reply: {
                  id: x?.uniqueId,
                  title: x?.value
                }
              }
            };
            buttonObj.push(obj);
            obj = {};
          });
        }

        // console.log(buttonObj, "inside media + buttons");
        let jsonForParent;
        if (node?.data?.value?.isImage == true) {
          jsonForParent = {
            interactive: {
              header: {
                type: "image",
                link: node?.data?.value?.imageLink
              },
              type: "button",
              body: {
                text: node?.data?.value?.body
              },
              // "footer":{
              //   "text":childOfThisParent[0].data?.value?.footer
              // },
              action: {
                buttons: buttonObj
              }
            }
          };
        } else if (node?.data?.value?.isPdf == true) {
          jsonForParent = {
            interactive: {
              header: {
                type: "document",
                link: node?.data?.value?.imageLink
              },
              type: "button",
              body: {
                text: node?.data?.value?.body
              },
              // "footer":{
              //   "text":childOfThisParent[0].data?.value?.footer
              // },
              action: {
                buttons: buttonObj
              }
            }
          };
        } else if (node?.data?.value?.isVideo == true) {
          jsonForParent = {
            interactive: {
              header: {
                type: "video",
                link: node?.data?.value?.imageLink
              },
              type: "button",
              body: {
                text: node?.data?.value?.body
              },
              // "footer":{
              //   "text":childOfThisParent[0].data?.value?.footer
              // },
              action: {
                buttons: buttonObj
              }
            }
          };
        }

        // console.log(jsonForParent, "inside media + button");

        tempObj = {
          ...tempObj,
          [node?.id]: jsonForParent
        };

        return true;
      } else if (node?.type === "textList") {
        // console.log("inside textList");
        let buttonObj = [];

        if (node?.data?.value?.sections.length > 0) {
          node?.data?.value?.sections.map((x, i) => {
            let temp = {
              id: x?.uniqueId,
              title: x?.title,
              description: x?.description
            };
            buttonObj.push(temp);
            temp = {};
          });
        }

        // console.log(buttonObj, "buttonObj");

        let jsonForParent = {
          interactive: {
            type: "list",
            header: {
              type: "text",
              text:
                node?.data?.value?.header == null
                  ? ""
                  : node?.data?.value?.header
            },
            body: {
              text: node?.data?.value?.body
            },
            footer: {
              text:
                node?.data?.value?.footer == null
                  ? ""
                  : node?.data?.value?.footer
            },
            action: {
              button: node?.data?.value?.title,
              sections: [
                {
                  rows: buttonObj
                  // title: node?.data?.value?.title,
                }
              ]
            }
          }
        };

        // console.log(jsonForParent, "inside text + list");

        // setJsonForAllParents((prevJson) => {
        //   console.log(prevJson, ">>>>>>>>>>>>");
        //   prevJson = {
        //     ...prevJson,
        //     [node.id]: jsonForParent,
        //   };

        //   console.log(prevJson, "prevJson");
        //   return prevJson;
        // });

        tempObj = {
          ...tempObj,
          [node?.id]: jsonForParent
        };

        return true;
      } else if (
        node?.type === "NewCommonNode" ||
        node?.type === "NewCommonNodeMedia" ||
        node?.type === "NewCommonNodeList" ||
        node?.type === "NewCommonNodeText"
      ) {
        //start from indexing, we will get data from there and type too,

        if (node?.data?.value?.indexing.length > 0) {
          let finalArray = [];
          node?.data?.value?.indexing?.map((item, index) => {
            //identify the type from here and make json accordingly

            let jsonForParent;
            if (item.type === "textButtons") {
              let data = node?.data?.value["textButtons"][item.localIndex];

              let buttonArray = [];
              if (data?.buttons?.length > 0) {
                data?.buttons?.map((x, i) => {
                  let obj = {
                    [i]: {
                      type: "reply",
                      reply: {
                        id: x?.uniqueId,
                        title: x?.title
                      }
                    }
                  };
                  buttonArray.push(obj);
                  obj = {};
                });
              }

              jsonForParent = {
                interactive: {
                  header: {
                    type: "text",
                    text: data?.header
                  },
                  type: "button",
                  body: {
                    text: data?.body
                  },
                  footer: {
                    text: data?.footer
                  },
                  action: {
                    buttons: buttonArray
                  }
                }
              };

              console.log({ jsonForParent });
              finalArray.push(jsonForParent);
            } else if (item.type === "mediaButtons") {
              let data = node?.data?.value["mediaButtons"][item.localIndex];

              let buttonArray = [];
              if (data?.buttons?.length > 0) {
                data?.buttons?.map((x, i) => {
                  let obj = {
                    [i]: {
                      type: "reply",
                      reply: {
                        id: x?.uniqueId,
                        title: x?.title
                      }
                    }
                  };
                  buttonArray.push(obj);
                  obj = {};
                });
              }

              // console.log(buttonObj, "inside media + buttons");
              if (data?.isImage == true) {
                jsonForParent = {
                  interactive: {
                    header: {
                      type: "image",
                      link: data?.fileUrl
                    },
                    type: "button",
                    body: {
                      text: data?.body
                    },
                    // "footer":{
                    //   "text":childOfThisParent[0].data?.value?.footer
                    // },
                    action: {
                      buttons: buttonArray
                    }
                  }
                };
              } else if (data?.isPdf == true) {
                jsonForParent = {
                  interactive: {
                    header: {
                      type: "document",
                      link: data?.fileUrl
                    },
                    type: "button",
                    body: {
                      text: data?.body
                    },
                    // "footer":{
                    //   "text":childOfThisParent[0].data?.value?.footer
                    // },
                    action: {
                      buttons: buttonArray
                    }
                  }
                };
              } else if (data?.isVideo == true) {
                jsonForParent = {
                  interactive: {
                    header: {
                      type: "video",
                      link: data?.fileUrl
                    },
                    type: "button",
                    body: {
                      text: data?.body
                    },
                    // "footer":{
                    //   "text":childOfThisParent[0].data?.value?.footer
                    // },
                    action: {
                      buttons: buttonArray
                    }
                  }
                };
              }

              // console.log(jsonForParent, "inside media + button");

              finalArray.push(jsonForParent);
            } else if (item.type === "textLists") {
              let data = node?.data?.value["textLists"][item.localIndex];
              let tempArr = [];

              if (data?.sections?.length > 0) {
                console.log("sections available");
                data?.sections.map(section => {
                  let buttonArray = [];

                  if (section?.items?.length > 0) {
                    console.log("items available");

                    section?.items?.map(itm => {
                      let temp = {
                        id: itm?.uniqueId,
                        title: itm?.itemTitle,
                        description: itm?.itemDescription
                      };

                      console.log(temp, "temp here");
                      buttonArray.push(temp);
                    });
                  }

                  let tempObj = {
                    title: section?.sectionTitle,
                    rows: buttonArray
                  };

                  tempArr.push(tempObj);
                });
              }

              let jsonForParent = {
                interactive: {
                  type: "list",
                  header: {
                    type: "text",
                    text: data.header
                  },
                  body: {
                    text: data?.body
                  },

                  action: {
                    button: data?.listTitle,
                    sections: tempArr
                  }
                }
              };

              finalArray.push(jsonForParent);
            } else if (item.type === "texts") {
              let data = node?.data?.value["texts"][item.localIndex];

              let tempObj = {
                type: "text",
                text: {
                  body: data?.value
                }
              };

              finalArray.push(tempObj);
            }
            tempObj = {
              ...tempObj,
              [node?.id]: finalArray
            };
          });
        }
      } else if (node?.type === "Template") {
        // console.log("inside template");
        let jsonForParent;

        let variables;
        let variableArray = [];
        let valuesArray = [];
        if (node?.data?.value?.variables.length > 0) {
          node?.data?.value?.variables.map(x => {
            // let obj = {
            //   id: x.id,
            //   name: x.name,
            //   value: x.dropdownValue == -2 ? x.textValue : x.dropdownValue,
            // };

            variableArray.push(x.dropdownValue);
            valuesArray.push(x.textValue);
          });
        }
        let carouselArray = [];
        if (node?.data?.value?.carousel?.length > 0) {
          node?.data?.value?.carousel?.map((item, ind) => {
            let carouselVariables = [];
            let caraouselValues = [];
            if (item?.variables?.length > 0) {
              item?.variables?.map(varItem => {
                carouselVariables.push(varItem?.dropdownValue);
                caraouselValues.push(varItem?.textValue);
              });
            }
            if (item.fileType === "video") {
              let caraouselObj = {
                video: item.fileUrl,
                variables: carouselVariables,
                values: caraouselValues
              };

              carouselArray.push(caraouselObj);
            } else if (item.fileType === "image") {
              let caraouselObj = {
                image: item.fileUrl,
                variables: carouselVariables,
                values: caraouselValues
              };
              carouselArray.push(caraouselObj);
            }
          });
        }

        if (node?.data?.value?.imageLink !== "") {
          jsonForParent = {
            template: {
              id: node.data?.value?.id,
              link: node.data?.value?.imageLink
                ? node.data?.value?.imageLink
                : "",
              variables: variableArray,
              values: valuesArray,
              carousel: carouselArray
            }
          };
        } else {
          jsonForParent = {
            template: {
              id: node.data?.id,
              variables: variableArray,
              values: valuesArray,
              carousel: carouselArray
            }
          };
        }

        tempObj = {
          ...tempObj,
          [node?.id]: jsonForParent
        };
      } else if (node?.type === "textLink") {
        // console.log("inside textLink");
        let jsonForParent = {
          interactive: {
            type: "cta_url",
            header: {
              type: "text",
              text:
                node?.data?.value?.header == null
                  ? ""
                  : node?.data?.value?.header
            },
            body: {
              text: node?.data?.value?.body
            },
            footer: {
              text:
                node?.data?.value?.footer == null
                  ? ""
                  : node?.data?.value?.footer
            },
            action: {
              name: "cta_url",
              parameters: {
                display_text: node?.data?.value?.buttonTitle,
                url: node?.data?.value?.buttonUrl
              }
            }
          }
        };

        tempObj = {
          ...tempObj,
          [node?.id]: jsonForParent
        };
      } else if (node?.type === "singleProduct") {
        console.log("inside singleProduct", node?.data?.value);

        let jsonForParent = {
          interactive: {
            type: "product",
            body: {
              text: node?.data?.value?.body ? node?.data?.value?.body : ""
            },
            footer: {
              text: node?.data?.value?.footer ? node?.data?.value?.footer : ""
            },
            action: {
              catalog_id: node?.data?.value?.catalogueId
                ? node?.data?.value?.catalogueId
                : "",
              product_retailer_id: node?.data?.value?.retailerId?.retailerId
                ? node?.data?.value?.retailerId?.retailerId
                : ""
            }
          }
        };

        console.log(jsonForParent, "json for parent single");
        tempObj = {
          ...tempObj,
          [node?.id]: jsonForParent
        };
      } else if (node?.type === "multiProduct") {
        // console.log("inside multiProduct");
        let buttonObj = [];
        if (node?.data?.value?.sections.length > 0) {
          node?.data?.value?.sections.map((x, i) => {
            let retailerArray = [];

            if (x.retailerId.length > 0) {
              x.retailerId.map((y, ind) => {
                let obj = {
                  product_retailer_id: y?.retailerId
                };
                retailerArray.push(obj);
                obj = {};
              });
            }

            let temp = {
              title: x.title,
              product_items: retailerArray
            };

            buttonObj.push(temp);
            temp = {};
          });
        }

        let jsonForParent = {
          interactive: {
            type: "product_list",
            header: {
              type: "text",
              text: node?.data?.value?.header ? node?.data?.value?.header : ""
            },
            body: {
              text: node?.data?.value?.body ? node?.data?.value?.body : ""
            },
            footer: {
              text: node?.data?.value?.footer ? node?.data?.value?.footer : ""
            },
            action: {
              catalog_id: node?.data?.value?.catalogueId
                ? node?.data?.value?.catalogueId
                : "",
              sections: buttonObj
            }
          }
        };

        // console.log(jsonForParent, "json for parent multi product");
        tempObj = {
          ...tempObj,
          [node?.id]: jsonForParent
        };

        // console.log(tempObj, "temp obj multi product");
        return true;
      } else if (node?.type === "InterventionNode") {
        if (node?.data?.value?.text !== "") {
          tempObj = {
            ...tempObj,
            [node.id]: {
              type: "Intervention",
              text: node?.data?.value?.text
            }
          };
        } else {
          tempObj = {
            ...tempObj,
            [node.id]: {
              type: "Intervention"
            }
          };
        }
      } else if (node?.type === "AttributeNode") {
        // console.log("inside attribute node");
        let jsonForParent = {
          type: "Attribute",
          id: node?.uniqueId,
          attributeId: node?.data?.value?.id,
          value: node?.data?.value?.value
        };
        tempObj = {
          ...tempObj,
          [node?.id]: jsonForParent
        };
      } else if (node?.type === "TagsNode") {
        // console.log("inside tags node");

        const idsArray = [];

        if (node?.data?.value?.id?.length > 0) {
          node?.data?.value?.id?.map(x => {
            if (x?.value !== "" && x?.value !== null && x?.value !== undefined)
              idsArray.push(x?.value);
          });
        }

        let jsonForParent = {
          type: "Tags",
          id: node?.uniqueId,
          tagsId: idsArray
        };
        tempObj = {
          ...tempObj,
          [node?.id]: jsonForParent
        };
      } else if (node?.type === "QuestionNode") {
        let jsonForParent = {
          type: "Question",
          id: node?.uniqueId,
          question: node?.data?.value?.Question,
          attribute: node?.data?.value?.Attribute?.id,
          format: node?.data?.value?.Format,
          attempt: node?.data?.value?.Attempt,
          validation: node?.data?.value?.Validation,
          extraOptions:
            node?.data?.value?.Format?.label === "Number" ||
            node?.data?.value?.Format?.label === "Regex"
              ? node?.data?.value?.ExtraOptions
              : null
        };

        tempObj = {
          ...tempObj,
          [node?.id]: jsonForParent
        };
      } else if (node?.type === "LocationNode") {
        let jsonForParent = {
          type: "interactive",
          id: node?.uniqueId,
          latAttributeId: node?.data?.value?.Attribute1?.id,
          longAttributeId: node?.data?.value?.Attribute2?.id,
          interactive: {
            type: "location_request_message",
            body: {
              text: node?.data?.value?.Question
            },
            action: {
              name: "send_location"
            }
          }
        };
        tempObj = {
          ...tempObj,
          [node?.id]: jsonForParent
        };
      } else if (node?.type === "AddressNode") {
        let jsonForParent = {
          type: "interactive",
          attribute: node?.data?.value?.Attribute1?.id
            ? node?.data?.value?.Attribute1?.id
            : null,

          interactive: {
            type: "address_message",
            header: {
              type: "text",
              text:
                node?.data?.value?.Header !== ""
                  ? node?.data?.value?.Header
                  : ""
            },
            body: {
              text: node?.data?.value?.Question
            }
          }
        };
        tempObj = {
          ...tempObj,
          [node?.id]: jsonForParent
        };
      } else if (node?.type === "AskMediaNode") {
        let jsonForParent = {
          type: "Question",
          id: node?.uniqueId,
          question: node?.data?.value?.Question,
          attribute: node?.data?.value?.Attribute?.id,
          format: node?.data?.value?.Format,
          attempt: node?.data?.value?.Attempt,
          validation: node?.data?.value?.Validation
        };

        tempObj = {
          ...tempObj,
          [node?.id]: jsonForParent
        };
      } else if (node?.type === "NewListNode") {
        let tempArr = [];

        if (node?.data?.value?.sections?.length > 0) {
          node?.data?.value?.sections.map(section => {
            let buttonArray = [];

            if (section?.item?.length > 0) {
              section?.item?.map(itm => {
                let temp = {
                  id: itm?.uniqueId,
                  title: itm?.itemTitle,
                  description: itm?.itemDescription
                };

                buttonArray.push(temp);
              });
            }

            let tempObj = {
              title: section?.title,
              rows: buttonArray
            };

            tempArr.push(tempObj);
          });
        }

        let jsonForParent = {
          interactive: {
            type: "list",
            header: {
              type: "text",
              text: node?.data?.value?.header
            },
            body: {
              text: node?.data?.value?.body
            },

            action: {
              button: node?.data?.value?.title,
              sections: tempArr
            }
          }
        };

        tempObj = {
          ...tempObj,
          [node?.id]: jsonForParent
        };
      } else if (node?.type === "FormNode") {
        let jsonForParent = {
          type: "Form",
          header: {
            text: node?.data?.value?.header ? node?.data?.value?.header : ""
          },
          body: {
            text: node?.data?.value?.body ? node?.data?.value?.body : ""
          },
          footer: {
            text: node?.data?.value?.footer ? node?.data?.value?.footer : ""
          },
          button_title: node?.data?.value?.buttonTitle
            ? node?.data?.value?.buttonTitle
            : "",

          id: node?.data?.value?.formId?.value,
          uniqueId: node?.uniqueId
        };

        tempObj = {
          ...tempObj,
          [node?.id]: jsonForParent
        };
      } else if (node?.type === "RestartNode") {
        tempObj = {
          ...tempObj,
          [node.id]: {
            type: "Restart"
          }
        };
      } else if (node?.type === "AINode") {
        tempObj = {
          ...tempObj,
          [node.id]: {
            type: "AI",
            body: {
              text: node?.data?.value?.body ? node?.data?.value?.body : ""
            }
          }
        };
      } else if (node?.type === "AppointmentNode") {
        let jsonForParent = {};
        if (node?.data?.value?.paymentBoolean === true) {
          jsonForParent = {
            id: node?.data?.value?.appointmentForm,
            appointment: {
              need_payment: node?.data?.value?.paymentBoolean,
              payment_amount: node?.data?.value?.paymentAmount,
              payment_currency: node?.data?.value?.paymentCurrency,
              order_template_id: node?.data?.value?.paymentTemplateId
            },
            type: "Form",
            footer: {
              text: null
            },
            header: {
              text: null
            },
            body: {
              text: node?.data?.value?.body
            },
            uniqueId: node?.uniqueId,
            button_title: node?.data?.value?.buttonTitle
          };
        } else if (node?.data?.value?.paymentBoolean === false) {
          jsonForParent = {
            id: node?.data?.value?.appointmentForm,
            appointment: {
              need_payment: node?.data?.value?.paymentBoolean
              // payment_amount: node?.data?.value?.paymentAmount,
              // payment_currency: node?.data?.value?.paymentCurrency,
              // order_template_id: node?.data?.value?.paymentTemplateId,
            },
            type: "Form",
            footer: {
              text: null
            },
            header: {
              text: null
            },
            body: {
              text: node?.data?.value?.body
            },
            uniqueId: node?.uniqueId,
            button_title: node?.data?.value?.buttonTitle
          };
        }

        console.log(jsonForParent, "inside Appointment Node");
        tempObj = {
          ...tempObj,
          [node?.id]: jsonForParent
        };
      } else {
        // console.log("yyyyyyyyy text");

        tempObj = {
          ...tempObj,
          [node.id]: {
            type: "text",
            text: {
              body: node?.data?.value?.text
            }
          }
        };

        return true;
      }
    });

    // console.log(tempObj, "tempObj outside");
    setJsonForAllParents(tempObj);
    return tempObj;
  };
  // const handleSubmitData = () => {
  //   // const res = handleSetJsonForParent();
  //   // console.log(res, "res", nodes);

  //   setIsLoading(true);
  //   const response = handleSetJsonForParent();
  //   console.log(response, "response");
  //   const sortedNodes1 = sortNodesById(nodes);

  //   const allParentIds = extractParentIds(sortedNodes1);
  //   console.log(allParentIds, "new arr all parent ids");

  //   //make sure initial node doesnt have any incomming edge
  //   const initalNode = allParentIds[0].id;

  //   const masterArray = [];
  //   allParentIds.map((parentId) => {
  //     //   const childOfParentNode = getChildNodes(parentId.id, nodes).childList;

  //     const childOfParentNode = [];
  //     const newArr = childOfParentNode.filter((x) => {
  //       return (
  //         (x.type == "customButton" || x.type == "customListSection") &&
  //         x.data.triggerFor &&
  //         x.data.uniqueId
  //       );
  //     });

  //     newArr.map((x) => {
  //       const obj = {
  //         triggerFor: x.data.triggerFor,
  //         uniqueId: x.data.uniqueId,
  //       };

  //       masterArray.push(obj);
  //     });
  //   });
  //   let finalObject = {
  //     parent: response[initalNode],
  //   };

  //   console.log("initial node", initalNode);
  //   masterArray.map((obj) => {
  //     const triggerFor = obj?.triggerFor;
  //     finalObject = {
  //       ...finalObject,
  //       [obj.uniqueId]: response[triggerFor],
  //     };
  //   });

  //   console.log(finalObject, "final Object");

  //   handleSaveFlow(finalObject);
  // };

  const handleSubmitData = () => {
    // const res = handleSetJsonForParent();
    // console.log(res, "res", nodes);
    if (nodes?.length == 0) {
      error("You can not save with no nodes");
      return true;
    }
    setIsLoading(true);
    const response = handleSetJsonForParent();
    // console.log(response, "response");
    const sortedNodes1 = sortNodesById(nodes);

    const allParentIds = extractParentIds(sortedNodes1);
    // console.log(allParentIds, "new arr all parent ids");
    //make sure initial node doesnt have any incomming edge

    const masterArray = [];
    allParentIds.map((parent, ind) => {
      if (parent?.data?.triggerFor?.length > 0) {
        parent?.data?.triggerFor.map((x, i) => {
          // debugger

          let temp;
          if (parent?.type === "textList") {
            // temp = {
            //   uniqueId: allParentIds[ind]?.data?.value?.sections[i]?.uniqueId,
            //   triggerFor: x?.triggerForId,
            // };

            temp = {
              uniqueId: x?.uniqueId,
              triggerFor: x?.triggerForId
            };
          } else if (
            parent?.type === "AttributeNode" ||
            parent?.type === "TagsNode" ||
            parent?.type === "QuestionNode" ||
            parent?.type === "LocationNode" ||
            parent?.type === "AddressNode" ||
            parent?.type === "AskMediaNode" ||
            parent?.type === "FormNode" ||
            parent?.type === "AppointmentNode"
          ) {
            // console.log("inside here aaaaa");
            if (
              allParentIds[ind]?.uniqueId !== undefined &&
              x?.triggerForId !== undefined
            ) {
              // console.log("inside here aaaaa bbb");

              temp = {
                uniqueId: allParentIds[ind]?.uniqueId,
                triggerFor: x?.triggerForId
              };
            }
          } else {
            temp = {
              uniqueId: x?.uniqueId,
              triggerFor: x?.triggerForId
            };
          }

          masterArray.push(temp);
        });
      }
    });
    let finalObject = {
      parent: response[initialNode?.id]
    };

    // console.log(masterArray, "master Array");

    // console.log("initial node", initialNode);
    masterArray.map(obj => {
      if (obj?.uniqueId !== undefined) {
        const triggerFor = obj?.triggerFor;

        finalObject = {
          ...finalObject,
          [obj?.uniqueId]: response[triggerFor]
        };
      }
    });

    // console.log(finalObject, "final Object");

    handleSaveFlow(finalObject);
  };
  const handleSaveFlow = async flow => {
    try {
      // console.log(flow, "final response flow");
      let dataToSend;
      if (isEdit) {
        dataToSend = {
          finalObject: flow,
          nodes: nodes,
          edges: edges,
          _token: token,
          company_id: companyId,
          title: title,
          type: type,
          template_reply: templateReply,
          templates: templates,
          trigger: trigger,
          edit_id: isEdit
        };
      } else {
        dataToSend = {
          finalObject: flow,
          nodes: nodes,
          edges: edges,
          _token: token,
          company_id: companyId,
          title: title,
          type: type,
          template_reply: templateReply,
          templates: templates,
          trigger: trigger
        };
      }

      console.log(dataToSend, "dataToSend");

      const res = await saveFlow(dataToSend);
      // console.log(res, "final response from api");
      if (res.status_code === 200) {
        success("Flow saved successfully");
        // window.location.href = `${BACKEND_URI}flows`;
        setIsEdit(res?.flow_id);
      }
    } catch (error) {
      console.log(error, "error while trying to save flow");
    } finally {
      setIsLoading(false);
    }
  };

  // const addingTestNode = async (type) => {
  //   try {
  //     setNodes((prevNodes) => {
  //       // const position2 = {
  //       //   x: Math.random() * (100 - 10) + 10,
  //       //   y: Math.random() * (100 - 10) + 10,
  //       // };

  //       let newPos;
  //       if (prevNodes.length == 0) {
  //         newPos = {
  //           x: Math.random() * (100 - 10) + 10,
  //           y: Math.random() * (100 - 10) + 10,
  //         };
  //       } else {
  //         let position1 = prevNodes[prevNodes.length - 1]?.position;
  //         newPos = {
  //           x: position1?.x + 200,
  //           y: position1?.y,
  //         };
  //       }

  //       const nextNodeId = getNextNodeId(prevNodes);
  //       const nodeToadd = {
  //         id: nextNodeId,
  //         type: type,
  //         uniqueId:
  //           type == "AttributeNode" ||
  //           type == "TagsNode" ||
  //           type == "QuestionNode" ||
  //           type == "LocationNode" ||
  //           type == "AskMediaNode"
  //             ? generateUniqueId()
  //             : null,
  //         data: {
  //           label: `Parent Node ${nextNodeId}`,
  //           value: "",
  //           type: "testingNode",
  //           triggerFor: [],
  //         },
  //         position: newPos,
  //         style: {
  //           width: 270,
  //           backgroundColor: "white",
  //           borderRadius: "8px",
  //           boxShadow: "0px 0px 25px #00000014",
  //         },
  //       };

  //       prevNodes.push(nodeToadd);
  //       return [...prevNodes];
  //     });
  //   } catch (error) {
  //     console.log(error, "Error while trying to add test node");
  //   }
  // };

  const addingTestNode = async type => {
    try {
      setNodes(prevNodes => {
        let newPos;
        // const reactFlowWrapperRect = reactFlowWrapper.current.getBoundingClientRect();
        // const centerX = reactFlowWrapperRect.width / 2;
        // const centerY = reactFlowWrapperRect.height / 2;

        const NODE_WIDTH = 270;
        const currentOverlapOffset = 0;
        const {
          height,
          width,
          transform: [transformX, transformY, zoomLevel]
        } = store.getState();

        const zoomMultiplier = 1 / zoomLevel;
        const centerX =
          -transformX * zoomMultiplier + (width * zoomMultiplier) / 2;
        const centerY =
          -transformY * zoomMultiplier + (height * zoomMultiplier) / 2;
        const nodeWidthOffset = NODE_WIDTH / 2;
        // const nodeHeightOffset = NODE_HEIGHT / 2;

        if (prevNodes.length === 0) {
          newPos = {
            x: centerX - nodeWidthOffset + currentOverlapOffset,
            y: centerY
          };
        } else {
          let position1 = prevNodes[prevNodes.length - 1]?.position;
          newPos = {
            x: centerX - nodeWidthOffset + currentOverlapOffset,
            y: centerY
          };
        }

        const nextNodeId = getNextNodeId(prevNodes);
        const nodeToadd = {
          id: nextNodeId,
          type: type,
          uniqueId:
            type === "AttributeNode" ||
            type === "TagsNode" ||
            type === "FormNode" ||
            type === "QuestionNode" ||
            type === "LocationNode" ||
            type === "AddressNode" ||
            type === "AskMediaNode" ||
            type === "AppointmentNode"
              ? generateUniqueId()
              : null,
          data: {
            label: `Parent Node ${nextNodeId}`,
            value: "",
            type: type,
            triggerFor: []
          },
          position: newPos,
          style: {
            width: 270,
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: "0px 0px 25px #00000014"
          }
        };

        prevNodes.push(nodeToadd);
        return [...prevNodes];
      });
    } catch (error) {
      console.log(error, "Error while trying to add test node");
    }
  };

  const proOptions = { hideAttribution: true };

  return (
    <>
      {isLoading == true ? (
        <>
          {" "}
          <LoaderComponent />
        </>
      ) : (
        <>
          {verifyToken == true ? (
            <div className="flow-container">
              <div className="flow-sidebar dndnode input">
                <div className="flow-sidebar-box">
                  <div className="flow-sidebar-title">message types</div>
                  <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("NewCommonNode");
                    }}
                  >
                    <i className="fas fa-text-width"></i>
                    Text + Buttons
                    {/* <span className="coming-soon-text">coming soon</span> */}
                  </div>
                  <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("NewCommonNodeMedia");
                    }}
                  >
                    <i className="fas fa-images"></i>
                    Media + Buttons
                    {/* <span className="coming-soon-text">coming soon</span> */}
                  </div>

                  {/* <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("textList");
                    }}
                  >
                    <i className="fas fa-list"></i>
                    Text + list
                  </div> */}

                  <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("NewCommonNodeList");
                    }}
                  >
                    <i className="fas fa-list"></i>
                    Text + List
                  </div>
                  {/* <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("textLink");
                    }}
                  >
                    <i className="fas fa-link"></i>
                    Text + Url
                  </div> */}

                  <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("NewCommonNodeText");
                    }}
                  >
                    <i className="fas fa-i-cursor"></i>
                    Text
                  </div>

                  <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("singleProduct");
                    }}
                  >
                    <i className="fab fa-product-hunt"></i> Single Product
                    {/* <span className="coming-soon-text">coming soon</span> */}
                  </div>
                  <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("FormNode");
                    }}
                  >
                    <i className="fab fa-wpforms"></i> Form
                    {/* <span className="coming-soon-text">coming soon</span> */}
                  </div>
                  <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("multiProduct");
                    }}
                  >
                    <i className="fas fa-luggage-cart"></i>
                    Multi Product
                    {/* <span className="coming-soon-text">coming soon</span> */}
                  </div>
                  <div
                    className="flow-sidebar-btn flow-temp"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("Template");
                    }}
                  >
                    <i className="fas fa-bolt"></i>
                    Template
                    {/* <span className="coming-soon-text">coming soon</span> */}
                  </div>

                  {/* <div
                    onClick={() => {
                      addingTestNode("FormBuilder");
                    }}
                    style={{ cursor: "pointer" }}
                    className="flow-sidebar-btn"
                  >
                    Form Builder
                    <span className="coming-soon-text">coming soon</span>
                  </div> */}
                  {/* 
                  <div
                    onClick={() => {
                      addingTestNode("NewCommonNode");
                    }}
                    style={{ cursor: "pointer" }}
                    className="flow-sidebar-btn"
                  >
                    New common node
                  </div> */}

                  {/* <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setNodes(() => {
                        return [];
                      });
                      setEdges((prevEdges) => {
                        return [];
                      });
                      setErrorsArray((errorsArr) => {
                        return [];
                      });
                    }}
                    className="flow-sidebar-btn"
                  >
                    Delete ALl Nodes{" "}
                  </div> */}

                  {/* <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("PaymentNode");
                    }}
                  >
                    <i className="fab fa-xing-square"></i> Payment Node
                  </div> */}
                  <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("AppointmentNode");
                    }}
                  >
                    <i className="fab fa-xing-square"></i> Appointment Node
                  </div>
                  <div className="flow-sidebar-title mt-4">Action</div>

                  <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("AttributeNode");
                    }}
                  >
                    <i className="fab fa-xing-square"></i> Attribute
                  </div>
                  <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("TagsNode");
                    }}
                  >
                    <i className="fas fa-tags"></i>
                    Tags
                  </div>
                  <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("QuestionNode");
                    }}
                  >
                    <i className="fas fa-question"></i>
                    Ask Question
                  </div>
                  <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("AskMediaNode");
                    }}
                  >
                    <i className="fas fa-file-import"></i>
                    Ask Media
                  </div>
                  <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("InterventionNode");
                    }}
                  >
                    <i className="fas fa-user-tie"></i>
                    Intervention Node
                  </div>
                  <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("LocationNode");
                    }}
                  >
                    <i className="fas fa-map-marker-alt"></i> Ask Location
                  </div>
                  <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("AddressNode");
                    }}
                  >
                    <i className="fas fa-map-marker-alt"></i> Ask Address
                  </div>
                  {/* <div className="flow-sidebar-btn">
                    asdasApi Requests{" "}
                    <span className="coming-soon-text">coming soon</span>
                  </div>
                  <div className="flow-sidebar-btn">
                    Api Requasdsaests{" "}
                    <span className="coming-soon-text">coming soon</span>
                  </div> */}

                  <div
                    className="flow-sidebar-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addingTestNode("RestartNode");
                    }}
                  >
                    <i className="fas fa-recycle"></i> Restart Node
                  </div>
                  {(companyId === "MTk=" || companyId === "MQ==") && (
                    <div
                      className="flow-sidebar-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        addingTestNode("AINode");
                      }}
                    >
                      <i className="fas fa-comment-dots"></i> AI Node
                    </div>
                  )}
                  <div
                    className="flow-sidebar-btn"
                    style={{ flex: "1 0 100%" }}
                  >
                    Api Requests{" "}
                    <span className="coming-soon-text">coming soon</span>
                  </div>
                </div>
                <div className="sidebar-bottom-btn">
                  <button
                    onClick={() => {
                      // console.log("Button clicked");
                      toggleGoBackModal();
                    }}
                    style={{ cursor: "pointer" }}
                    className="flow-sidebar-btn comn-btn comn-btn-border"
                  >
                    Go Back
                  </button>
                  {isChildLoading == true || isValid == false ? (
                    <>
                      <button
                        disabled={true}
                        style={{ backgroundColor: "grey", cursor: "default" }}
                        className="flow-sidebar-btn comn-btn"
                        onClick={() => {}}
                      >
                        Save
                      </button>
                    </>
                  ) : isChildLoading == false && isValid == true ? (
                    <>
                      <button
                        onClick={() => {
                          handleSubmitData();
                          setSubmitCounter(count => count + 1);
                        }}
                        style={{ cursor: "pointer" }}
                        className="flow-sidebar-btn comn-btn"
                      >
                        Save
                      </button>
                    </>
                  ) : null}
                </div>
                {/* <div className="flow-sidebar-btn">
            <i className="fa-solid fa-text-width"></i>Image
          </div>
          <div className="flow-sidebar-btn">
            <i className="fa-solid fa-text-width"></i>Text
          </div> */}
                {/* <Sidebar
                  sidebarState={sidebarState}
                  selectedNode={selectedNode}
                  toggleDrawer={toggleDrawer}
                  setSelectedNode={setSelectedNode}
                  childNodesofSelectedNode={childNodesofSelectedNode}
                  setChildNodesofSelectedNode={setChildNodesofSelectedNode}
                  handleAddNode={addCustomNode} /> */}
              </div>
              <div className="flow-main-body" ref={reactFlowWrapper}>
                <div className="warning-row">
                  {/* <div className="warning-text">
                    Enter "." after completing your text
                  </div> */}

                  <div className="warning-text">
                    You can use {`{{name}}`} or {`{{phone}}`} to set dynamic
                    values
                  </div>
                </div>
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  nodeTypes={nodeTypes}
                  edgeTypes={edgeTypes}
                  onConnect={onConnect}
                  onInit={setReactFlowInstance}
                  onDrop={onDrop}
                  // nodesDraggable={false}
                  onDragOver={onDragOver}
                  fitView={isEdit ? true : false}
                  // fitView
                  // minZoom={}
                  minZoom={0.1}
                  ref={reactFlowWrapper}
                  proOptions={proOptions}
                >
                  <Controls position="top-right" />
                  <Background />
                  <MiniMap />
                </ReactFlow>
              </div>
              <div>
                {previewForWhatsapp && (
                  // <div style={{ width: "30%", backgroundColor: "#DCF8C6" }}></div>
                  <CustomSideBarPreview
                    isChildLoading={isChildLoading}
                    setPreviewForWhatsapp={setPreviewForWhatsapp}
                    selectedNode={handleSettingSelectedNode(selectedNode)}
                    // childOfThisNode={
                    //  getChildNodes(selectedNode, nodes).childList
                    // }
                  />
                )}
              </div>
            </div>
          ) : null}

          {goBackModalOpen && (
            <Modal
              onClose={() => {
                setGoBackModalOpen(!goBackModalOpen);
              }}
              open={goBackModalOpen}
            >
              <Box sx={style}>
                <Typography id="modal-modal-description">
                  Please make sure you have saved all your changes before going
                  back !
                </Typography>
                <div className="lost-btn-row">
                  <button
                    className="comn-btn comn-btn-border"
                    onClick={() => {
                      toggleGoBackModal();
                    }}
                  >
                    Stay
                  </button>
                  <button
                    className="comn-btn"
                    onClick={() => {
                      window.location.href = `${BACKEND_URI}flows`;
                    }}
                  >
                    Back
                  </button>
                </div>
              </Box>
            </Modal>
          )}
        </>
      )}
    </>
  );
}

export default MainFile;
